<template>
  <div class="container-fluid mt-2 pt-2 text-left">
    <h3 class="d-flex justify-content-between">
      <span class="text-center" v-if="title"
        ><b-icon-printer /> {{ title }}</span
      >

      <span><b-icon-filter-circle /> Filtros</span>
    </h3>
    <hr />
    <div class="row filters" style="font-size: 12px">
      <div
        class="col-12 col-sm-6 col-md-4 pr-1 pl-1"
        v-if="!opts || !opts.empresas || opts.empresas.visible"
      >
        <select-empresa-clinica
          :multiple="!opts || opts.empresas.visible"
          @empresa="empresaSelect"
          :withConsultorio="false"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-4 pr-1 pl-1">
        <label><b-icon-calendar /> Periodo</label>
        <div class="row">
          <div class="col-6 pr-1">
            <b-input
              type="date"
              style="padding:3px"
              v-model="filtros.periodo.dt_i"
            />
          </div>
          <div class="col-6 pl-1">
            <b-input
              type="date"
              style="padding:3px"
              v-model="filtros.periodo.dt_f"
            />
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-6 col-md-4 pr-1 pl-1"
        v-if="!opts || !opts.users || opts.users.visible"
      >
        <select-user @change="selectUsers" />
      </div>
      <div class="col-12 col-sm-6 col-md-4 pr-1 pl-1">
        <select-entidades-simples @change="selectMedicos" />
      </div>
      <div class="col-12 col-sm-6 col-md-4 pr-1 pl-1">
        <select-especialidade
          :multipleEspecialidade="true"
          @input="selectEspecialidades"
        />
      </div>
      <div
        class="col-12 col-sm-6 col-md-4 pr-1 pl-1"
        v-if="!opts || !opts.pacientes || opts.pacientes.visible"
      >
        <label><i class="fab fa-accessible-icon" /> Paciente(s)</label>

        <select-paciente
          :showBotaoAdd="false"
          :showFidelizacoes="false"
          :showBotaoEdit="false"
          @paciente="selectPaciente"
          :multiple="true"
          :selecionado="this.filtros.pacientes"
        />
      </div>
      <div class="col-12 text-center mt-2 pr-1 pl-1">
        <slot></slot>
      </div>
      <div class="col-12 text-center mt-2">
        <b-btn variant="info" block size="lg" @click="filtrar"
          ><b-icon-search /> Filtrar</b-btn
        >
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import SelectEmpresaClinica from "../../components/common/SelectEmpresaClinica.vue";
import SelectUser from "../common/SelectUser.vue";
import SelectEntidadesSimples from "../common/SelectEntidadesSimples.vue";
import SelectEspecialidade from "../common/SelectEspecialidade.vue";
import SelectPaciente from "../common/SelectPaciente.vue";
import moment from "moment";

export default {
  components: {
    SelectEmpresaClinica,
    SelectUser,
    SelectEntidadesSimples,
    SelectEspecialidade,
    SelectPaciente
  },
  props: {
    opts: Object,
    title: String,
    initial: Object
  },
  mounted() {
    if (this.initial) {
      this.filtros = { ...this.filtros, ...this.initial };
    }
  },
  data() {
    return {
      filtros: {
        empresas: [],
        periodo: {
          dt_i: moment().format("YYYY-MM-DD"),
          dt_f: moment().format("YYYY-MM-DD")
        },
        usuarios: [],
        pacientes: null,
        medicos: [],
        especialidades: []
      }
    };
  },
  watch: {
    initial: {
      deep: true,
      handler() {
        if (this.initial) {
          this.filtros = { ...this.filtros, ...this.initial };
        }
      }
    }
  },
  computed: {},
  methods: {
    empresaSelect(emp) {
      this.filtros.empresas = emp.map(i => {
        return { id: i.value.id, sigla: i.value.razao_social };
      });
    },
    selectUsers(u) {
      this.filtros.usuarios = u;
    },
    selectMedicos(m) {
      this.filtros.medicos = m;
    },
    selectPaciente(p) {
      this.filtros.pacientes = p ? p.id : null;
    },
    filtrar() {
      this.$emit("filtrar", this.filtros);
    },
    selectEspecialidades(espe) {
      this.filtros.especialidades = espe;
    }
  }
};
</script>

<style lang="scss" scoped>
.filters {
  label {
    margin-bottom: 5px;
  }
  .form-control {
    font-size: 11px !important;
  }
}
</style>