<template>
  <div>
      <label style="margin-bottom:5px"><b-icon-person/> Usuário(s)</label>
    <v-select :multiple="multiple" :options="usuarios" v-model="val" :reduce="i=>i.value.id"  />
  </div>
</template>

<script>
import UsuariosLib from "../../libs/UsuariosLib";

export default {
  components: {},
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
      this.getUsuarios();
  },
  data() {
    return {
        usuarios:[],
        val:null
    };
  },
  watch: {
      val:{
          deep:true,
          handler(){
              this.$emit("change",this.val);
          }
      }
  },
  computed: {},
  methods: {
    async getUsuarios() {
      this.usuarios = (await UsuariosLib.get({ativos:true, perfil:['admin','secretaria']})).sort((a,b)=>{return a.nome>b.nome?1:-1}).map(u=>{
          return {
              label: u.nome,
              value: u
          }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>